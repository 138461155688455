import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile, faGlobe, faWind, faPerson } from '@fortawesome/free-solid-svg-icons';

function Resume() {
    const [safetyNet, setSafetyNet] = React.useState(false);
    const [wordPress, setWordPress] = React.useState(false);
    const [self, setSelf] = React.useState(false);
    const [flex, setFlex] = React.useState(false);

    return (
        <div className="mb-6 sm:m-12 rounded-2xl bg-slate-300 shadow-2xl flex flex-col justify-center content-center h-fit z-[-1]">
            <div className="flex justify-center">
                <h1 className="mb-10 mt-6 text-xl sm:text-4xl font-semibold text-noelle-blue">Projects</h1>
            </div>
            <div>
                <VerticalTimeline 
                layout="2-columns">
                    <VerticalTimelineElement
                    position="left"
                    contentStyle={{ background: '#012a48', color: '#fff'}}
                    contentArrowStyle={{ borderRight: '7px solid #012a48'}}
                    date="2023"
                    dateClassName="text-noelle-blue"
                    icon={<FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>} 
                    iconStyle={{ background: '#012a48', color: '#fff'}}
                    >
                        <h3 className="vertical-timeline-element-title projectTitle">Capstone</h3>
                        <h4 className="vertical-timeline-element-subtitle projectSubTitle">SafetyNet App</h4>
                        <p>
                            Our team's capstone project is a mobile application designed to combat human trafficking and domestic violence by providing users with a safe and reliable way to share their location and record evidence in real-time.
                        </p>
                        {(safetyNet) 
                            ? 
                        <div>
                            <br></br>
                            <img src={require('../images/SafetyNet.png')} className="m-auto" width="150" height="300" alt="Landing page of the safetynet app that had a timer counting down"></img>
                            <p>
                                The app, tentatively called SafetyNet, allows users to create a safe contact list of trusted friends and family members. In the event of an emergency, the user can open the app, and without any other input from the user, send a text message to all contacts on their list, including their GPS location and other critical information.
                            </p>
                            <br />
                            <p>
                                To capture evidence, the app can also record video and audio in real-time, which can be used in court to support the user's case. We are still working on the method of storing this information whether in the cloud or just locally on the phone.
                            </p>
                            <br />
                            <p>
                                Overall, we believe that SafetyNet has the potential to make a significant impact in combating human trafficking and domestic violence by providing a reliable and accessible tool for those in need of help.
                            </p>
                        </div>
                        :
                        null
                    }
                    {(safetyNet)
                    ?
                        <button className="mt-3 font-bold" onClick={() => setSafetyNet(false)}>Show less...</button>
                    :
                        <button className="mt-3 font-bold" onClick={() => setSafetyNet(true)}>Show more...</button>
                    }
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                    position="right"
                    contentStyle={{ background: '#012a48', color: '#fff'}}
                    contentArrowStyle={{ borderRight: '7px solid #012a48'}}
                    date="2023"
                    dateClassName="text-noelle-blue"
                    icon={<FontAwesomeIcon icon={faWind}></FontAwesomeIcon>} 
                    iconStyle={{ background: '#012a48', color: '#fff'}}
                    >
                        <h3 className="vertical-timeline-element-title projectTitle">Self Directed</h3>
                        <h4 className="vertical-timeline-element-subtitle projectSubTitle">Vacuum Powered Hovercraft</h4>
                        <p>
                        I am currently working on a unique project that involves creating a hovercraft using limited resources such as a handheld vacuum, tupperware, and garbage bags. Despite the limited materials, I am using my creativity and problem-solving skills to design and assemble the hovercraft.
                        </p>
                        {(self) 
                            ? 
                        <div>
                            <br></br>
                            <img src={require('../images/vacuum.png')} className="m-auto" width="150" height="300" alt="Landing page of the safetynet app that had a timer counting down"></img>
                            <p>
                                To make the hovercraft even more exciting and innovative, I am integrating it with a Raspberry Pi computer. Using the Pi, I will be able to control a fan and adjust the speed and direction of the hovercraft remotely from my computer. This adds a whole new level of functionality and interactivity to the project, while still making use of the limited resources available to me.
                            </p>
                            <br />
                            <p>
                                Throughout the development process, I am using my technical skills and attention to detail to fine-tune the design and functionality of the hovercraft. Despite the challenges posed by limited resources, I am excited to see the final product in action and to share it with others who are interested in innovative and creative projects.
                            </p>
                        </div>
                        :
                        null
                    }
                    {(self)
                    ?
                        <button className="mt-3 font-bold" onClick={() => setSelf(false)}>Show less...</button>
                    :
                        <button className="mt-3 font-bold" onClick={() => setSelf(true)}>Show more...</button>
                    }
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                    position="left"
                    contentStyle={{ background: '#012a48', color: '#fff'}}
                    contentArrowStyle={{ borderRight: '7px solid #012a48'}}
                    date="2022"
                    dateClassName="text-noelle-blue"
                    icon={<FontAwesomeIcon icon={faGlobe}></FontAwesomeIcon>} 
                    iconStyle={{ background: '#012a48', color: '#fff'}}
                    >
                        <h3 className="vertical-timeline-element-title projectTitle">First Year Work Placement</h3>
                        <h4 className="vertical-timeline-element-subtitle projectSubTitle">Freelance WordPress creation for clients</h4>
                        <p>
                            During my first year work placement at NSCC, I had the opportunity to work on my own directly with clients to build custom WordPress websites tailored to their specific needs. This project allowed me to gain hands-on experience and to develop my skills in web development and project management.
                        </p>
                        {(wordPress) 
                            ? 
                        <div>
                            <br></br>
                            <img src={require('../images/websites.png')} className="m-auto" width="500" height="300" alt="Screen shot of the 3 sites I made for clients"></img>
                            <p>
                            Throughout the project, I worked closely with each client to understand their unique requirements and preferences. I communicated regularly with them to provide updates on the website's progress and to gather feedback and suggestions for improvement.
                        </p>
                        <br></br>
                        <p>
                            Thanks to this experience, I learned the importance of effective communication, project planning, and client collaboration in delivering successful web development projects. It also allowed me to develop my technical skills in WordPress development and I learned a lot about hosting.
                        </p>
                        <br></br>
                        <p>
                            Overall, this project was a valuable learning experience and helped me to develop my skills and confidence.</p>
                        </div>
                        :
                        null
                    }
                    {(wordPress)
                    ?
                        <button className="mt-3 font-bold" onClick={() => setWordPress(false)}>Show less...</button>
                    :
                        <button className="mt-3 font-bold" onClick={() => setWordPress(true)}>Show more...</button>
                    }
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                    position="right"
                    contentStyle={{ background: '#012a48', color: '#fff'}}
                    contentArrowStyle={{ borderRight: '7px solid #012a48'}}
                    date="2022"
                    dateClassName="text-noelle-blue"
                    icon={<FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>} 
                    iconStyle={{ background: '#012a48', color: '#fff'}}
                    >
                        <h3 className="vertical-timeline-element-title projectTitle">Code Flex</h3>
                        <h4 className="vertical-timeline-element-subtitle projectSubTitle">Coding Challenge for the First Years</h4>
                        <p>
                        Code Flex was a project I worked on with my class to create an exciting event for first-year IT students at our NSCC campus. We wanted to challenge their coding skills, so we came up with a series of events that tested their abilities in various areas.
                        </p>
                        {(flex) 
                            ? 
                        <div>
                            <br></br>
                            {/* <img src={require('../images/vacuum.png')} className="m-auto" width="150" height="300" alt="Landing page of the safetynet app that had a timer counting down"></img> */}
                            <p>
                            We named the event Code Flex and created a website to promote it. But what I'm most proud of is the project management involved in bringing it all together. We had to work closely as a team to make sure everything was organized and executed flawlessly. It was a massive undertaking, but the hard work paid off, and we successfully hosted the event.
                            </p>
                            <br />
                            <p>
                            The winning team received gift cards and even an interview for work placement! This project taught me a lot about project management skills, and I'm proud to have been a part of such a successful team effort.
                            </p>
                        </div>
                        :
                        null
                    }
                    {(flex)
                    ?
                        <button className="mt-3 font-bold" onClick={() => setFlex(false)}>Show less...</button>
                    :
                        <button className="mt-3 font-bold" onClick={() => setFlex(true)}>Show more...</button>
                    }
                    </VerticalTimelineElement>
                </VerticalTimeline>
            </div>
        </div>
    );
}

export default Resume;