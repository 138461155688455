import React from "react";
import { useInView } from 'react-intersection-observer';


function About() {

    return (
            <div className="my-10 mx-2">
                <div className="flex justify-center">
                    <h1 className="mb-10 text-xl sm:text-4xl font-semibold text-white">Resume</h1>
                </div>
                <img src={require('../images/Resume.png')} className="m-auto" alt="A copy of my resume"></img>
            </div>
    );
}

export default About;