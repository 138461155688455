import React from "react";

import Slider from "react-slick";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Projects() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 3,
    }
    return (
        <div className="mb-6 sm:m-12 rounded-2xl bg-slate-300 shadow-2xl flex flex-col justify-center content-center h-fit z-[-1]">
            <div className="flex justify-center">
                <h1 className="mb-10 mt-10 text-xl sm:text-4xl font-semibold">About Me</h1>
            </div>
            <div className="w-9/12 m-auto">
                <Slider {...settings}>
                    <p className="text-center font-bold text-2xl">Who is Brandon Roy?</p>

                    <div className="">
                        <img className="m-auto mt-6 rounded-full w-1/2 md:w-1/3 shadow-lg" src={require('../images/me.jpg')} alt="Me smiling with one of my cats"></img>
                    </div>  
                
                    <div className="flex flex-col p-10 text-base md:text-lg md:text-justify">
                        <p>Hello, my name is <b>Brandon Roy</b>, and I'm close to graduating from IT Web Programming at NSCC. I am a passionate and driven individual with a strong interest in technology and a desire to work on cool projects.
                        </p>
                        <br></br>
                        <p>
                        Throughout my college career, I gained valuable experience in a variety of IT-related fields, including web development, programming, and project management (although if I'm being honest my strong suit lies in programming and debugging). I have honed my skills in programming languages such as React and Python and have experience with tools such as Git, SQL, and Linux.
                        </p>
                        <br></br>
                        <p>
                        I am excited to take the next step in my career and apply my knowledge and skills to make a meaningful contribution to a company or organization.
                        </p>
                        <br></br>
                        <p>
                        Thank you for taking the time to learn more about me. If you're interested in discussing potential opportunities or collaborating on a project, please don't hesitate to contact me.
                        </p>
                    </div>
                    
                    <div className="h-56"></div>
                    <p className="text-center font-bold text-2xl">Skills</p>
                
                    <div className="flex flex-col p-10 text-base md:text-lg text-justify">
                        <p>
                            As a web developer, I have experience and am comfortable working with a variety of programming languages and technologies. I have a strong background in front-end web development, with a particular focus on React and JavaScript. I am skilled in problem-solving and debugging, and I enjoy tackling challenging programming tasks.
                        </p>
                        <br />
                        <p>
                            I also have experience working with back-end technologies, including PHP and Java, and am familiar with Git for version control. I pride myself on being adaptable and am constantly learning new technologies and techniques to improve my skills.
                        </p>
                        <br />
                        <p>
                            While I am less comfortable with server-side programming and database management, I am eager to learn and am confident in my ability to pick up new skills quickly. I have a solid foundation in hardware and network concepts, and am comfortable working with hardware components to set up and troubleshoot systems.
                        </p>
                        <br />
                        <p>
                            Overall, I am a versatile and dedicated developer with a passion for building high-quality web applications that meet the needs of clients and users.
                        </p>
                    </div>
                    
                </Slider>

            </div>
        </div>
    );
}

export default Projects;